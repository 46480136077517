import { useContext, useEffect, useState } from "react";
import { EmailProvider, PasswordProvider } from "../App";
import { useNavigate, useParams } from "react-router-dom";

import "../assets/css/snapgram.scss";
import "../assets/css/owl.carousel.min.scss";
import "../assets/css/owl.theme.default.min.scss";
import "../assets/css/stylesheet.scss";
import "../assets/css/custom.scss";
import "../assets/css/main.scss";

import "./Main.scss";

import photoOwner from "../assets/images/lurer-info-hospital_43058-164.jpg";
import { mainPageRoute } from "../constants";

const NAMES = {
  TEMANER: "TEMANER",
  TARACASHRJAN: "TARACASHRJAN",
  ASHXARH: "ASHXARH",
  MAMUL: "MAMUL",
  MEDIA: "MEDIA",
};

const MEDIA = () => {
  return (
    <div className="dropDown">
      <a href="https://www.lurer.com/?cat=38&amp;l=am">Տեսանյութեր</a>
      <a href="https://www.lurer.com/?cat=41&amp;l=am">Լուսանկարներ</a>
    </div>
  );
};

const MAMUL = () => {
  return (
    <div className="dropDown">
      <a href="https://www.lurer.com/?cat=34&amp;l=am">Հայաստան</a>
      <a href="https://www.lurer.com/?cat=33&amp;l=am">Աշխարհ</a>
    </div>
  );
};

const ASHXARH = () => {
  return (
    <div className="dropDown">
      <a href="https://www.lurer.com/?cat=21&amp;l=am">ԱՄՆ</a>
      <a href="https://www.lurer.com/?cat=20&amp;l=am">Եվրոպա</a>
      <a href="https://www.lurer.com/?cat=22&amp;l=am">Մերձավոր Արևելք</a>
      <a href="https://www.lurer.com/?cat=17&amp;l=am">Ռուսաստան</a>
      <a href="https://www.lurer.com/?cat=50&amp;l=am">Այլ</a>
    </div>
  );
};

const TARACASHRJAN = () => {
  return (
    <div className="dropDown">
      <a href="https://www.lurer.com/?cat=29&amp;l=am">Արցախ</a>
      <a href="https://www.lurer.com/?cat=24&amp;l=am">Թուրքիա</a>
      <a href="https://www.lurer.com/?cat=25&amp;l=am">Ադրբեջան</a>
      <a href="https://www.lurer.com/?cat=27&amp;l=am">Իրան</a>
    </div>
  );
};
const TEMANER = () => {
  return (
    <div
      class="dropDown"
      // style={{
      //   display: "block",
      // }}
    >
      <a href="https://www.lurer.com/?cat=10&amp;l=am">Քաղաքական</a>
      <a href="https://www.lurer.com/?cat=11&amp;l=am">Հասարակություն</a>
      <a href="https://www.lurer.com/?cat=13&amp;l=am">Տնտեսություն</a>
      <a href="https://www.lurer.com/?cat=15&amp;l=am">Իրավունք</a>
      <a href="https://www.lurer.com/?cat=36&amp;l=am">Արտակարգ պատահարներ</a>
      <a href="https://www.lurer.com/?cat=19&amp;l=am">Մշակույթ</a>
      <a href="https://www.lurer.com/?cat=16&amp;l=am">Սպորտ</a>
      <a href="https://www.lurer.com/?cat=48&amp;l=am">Հարցազրույցներ</a>
      <a href="https://www.lurer.com/?cat=49&amp;l=am">Վերլուծական</a>
      <a href="https://www.lurer.com/?cat=53&amp;l=am">Ծաղրանկարներ</a>
    </div>
  );
};

const Main = () => {
  const [selectedMenu, setSelectedMenu] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  console.log(selectedMenu, " selectedMenu");
  const [globalEmail, setGlobalEmail] = useContext(EmailProvider);
  const [globalPassword, setGlobalPassword] = useContext(PasswordProvider);

  useEffect(() => {
    console.log(params);
  }, []);
  // useEffect(() => {
  // if (!params.id || params.id != mainPageRoute) {
  //   navigate("/");
  // }
  // }, [params]);

  // useEffect(() => {
  //   if (!globalEmail && !globalPassword) {
  //     navigate("/");
  //   }
  // }, [globalEmail, globalPassword]);

  // if (!globalEmail && !globalPassword) return <span />;

  return (
    <>
      <header className="clearfix header1200">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
          className="headerContent"
        >
          <div
            className="logo"
            style={{
              float: "left",
            }}
          >
            <a href="https://www.lurer.com/?l=am">
              <img
                src="https://lurer.com/img/logo.png"
                title="Lurer.com - Օպերատիվ Լրատվական"
                alt="Lurer.com Logo"
              />
            </a>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="openMenu"></div>
            {/* <div
              style={{
                width: "max-content",
                height: "100%",
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 10px",
                paddingRight: "0",
                fontSize: "14px",
                cursor: "pointer",
              }}
              className="logoutMobile"
              onClick={() => {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                setGlobalPassword("");
                setGlobalEmail("");
              }}
            >
              <span>Ելք</span>
            </div> */}
          </div>
          <div className="headerRight clearfix">
            <div className="closeMenu"></div>
            {/* <div
              style={{
                height: "100%",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 20px",
                fontSize: "14px",
                cursor: "pointer",
              }}
              className="langBar"
              onClick={() => {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                setGlobalPassword("");
                setGlobalEmail("");
              }}
            >
              <span>Ելք</span>
            </div> */}
            <div className="langBar">
              <div className="currentLang">
                <a>
                  <img src="https://lurer.com/img/flags/am.png" />
                  <span>Հայերեն</span>
                </a>
              </div>
              <div className="selectLang">
                <a href="https://www.lurer.com/?p=551701&amp;l=ru">
                  <img src="https://lurer.com/img/flags/ru.png" />
                  <span>Русский</span>
                </a>
                <a href="https://www.lurer.com/?p=551701&amp;l=en">
                  <img src="https://lurer.com/img/flags/en.png" />
                  <span>English</span>
                </a>
              </div>
            </div>

            <nav className="menu clearfix">
              <div className="menuUnit">
                <a href="https://www.lurer.com/?news_line&amp;l=am">Լրահոս</a>
              </div>
              <div className="menuUnit">
                <a
                  onClick={() => {
                    if (selectedMenu === NAMES.TEMANER) setSelectedMenu("");
                    if (selectedMenu !== NAMES.TEMANER)
                      setSelectedMenu(NAMES.TEMANER);
                  }}
                  className="openDrop"
                >
                  Ըստ թեմաների
                  {selectedMenu === NAMES.TEMANER && <TEMANER />}
                </a>
              </div>
              <div className="menuUnit">
                <a
                  onClick={() => {
                    if (selectedMenu === NAMES.TARACASHRJAN)
                      setSelectedMenu("");
                    if (selectedMenu !== NAMES.TARACASHRJAN)
                      setSelectedMenu(NAMES.TARACASHRJAN);
                  }}
                  className="openDrop"
                >
                  Տարածաշրջան
                </a>
                {selectedMenu === NAMES.TARACASHRJAN && <TARACASHRJAN />}
              </div>
              <div className="menuUnit">
                <a
                  onClick={() => {
                    if (selectedMenu === NAMES.ASHXARH) setSelectedMenu("");
                    if (selectedMenu !== NAMES.ASHXARH)
                      setSelectedMenu(NAMES.ASHXARH);
                  }}
                  className="openDrop"
                >
                  Աշխարհ
                  {selectedMenu === NAMES.ASHXARH && <ASHXARH />}
                </a>
              </div>
              <div className="menuUnit">
                <a
                  onClick={() => {
                    if (selectedMenu === NAMES.MAMUL) setSelectedMenu("");
                    if (selectedMenu !== NAMES.MAMUL)
                      setSelectedMenu(NAMES.MAMUL);
                  }}
                  className="openDrop"
                >
                  Մամուլ
                  {selectedMenu === NAMES.MAMUL && <MAMUL />}
                </a>
              </div>
              <div className="menuUnit">
                <a
                  onClick={() => {
                    if (selectedMenu === NAMES.MEDIA) setSelectedMenu("");
                    if (selectedMenu !== NAMES.MEDIA)
                      setSelectedMenu(NAMES.MEDIA);
                  }}
                  className="openDrop"
                >
                  Մեդիա
                  {selectedMenu === NAMES.MEDIA && <MEDIA />}
                </a>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="mainTop ticker1200">
        <marquee className="ticker" behavior="scroll" direction="left">
          <a href="https://www.lurer.com/?p=551701&amp;l=am">
            <time>2:00</time> Իրանը և Թուրքիան համագործակցությամբ կարող են
            լուծել տարածաշրջանային և գլոբալ բազմաթիվ խնդիրներ․ Ռայիսի
          </a>
          <a href="https://www.lurer.com/?p=551703&amp;l=am">
            <time>1:51</time> Հրդեհել են Արիանա Հովսեփյանի ավտոմեքենան
            (տեսանյութ)
          </a>
          <a href="https://www.lurer.com/?p=551700&amp;l=am">
            <time>0:59</time> Ադրբեջանը չի կատարել ստանձնած պարտավորությունների
            մեծ մասը. Մորգենս Ենսեն
          </a>
          <a href="https://www.lurer.com/?p=551698&amp;l=am">
            <time>0:30</time> Փրկարարները Երևան-Սևան ավտոճանապարհին ավտոմեքենան
            դուրս են բերել ձորակից
          </a>
          <a href="https://www.lurer.com/?p=551696&amp;l=am">
            <time>23:57</time> «Մեզ համար անակնկալ էր, Հայաստանի վարչապետը դա
            ինքնուրույն է արել»․ Լավրով
          </a>
          <a href="https://www.lurer.com/?p=551694&amp;l=am">
            <time>23:24</time> Ֆրանսիան մերժել է Ռուսաստանի՝ Իլ-76-ի խոցման
            հարցով ՄԱԿ-ի ԱԽ նիստ անցկացնելու մասին դիմումը
          </a>
          <a href="https://www.lurer.com/?p=551693&amp;l=am">
            <time>23:20</time> Լավրովը հայտարարել է, որ ՌԴ–ն այլ երկրների վրա
            հարձակվելու ցանկություն չունի
          </a>
          <a href="https://www.lurer.com/?p=551692&amp;l=am">
            <time>23:09</time> ՀՀ-ում արհեստական բանականության գիտահետազոտական
            կենտրոնի արդիականացման համար կծախսվի 3 մլրդ 434 միլիոն դրամ
          </a>
          <a href="https://www.lurer.com/?p=551691&amp;l=am">
            <time>23:06</time> Կանչով դեպքի վայր մեկնող ՇՕ մեքենան մերկասառույցի
            պատճառով բախվել է այլ մեքենաներին
          </a>
          <a href="https://www.lurer.com/?p=551690&amp;l=am">
            <time>22:58</time> Երևանի ավագանու անդամ Նարինե Հայրապետյանը տուժող
            է ճանաչվել․ «Փաստինֆո»
          </a>
        </marquee>
        ﻿
      </div>
      <div className="main clearfix">
        <div className="mainSilver clearfix">
          <div className="mainCenterWrapper clearfix">
            <div id="make-count" data-id="551701"></div>
            <div className="mainCenterWrapperLeft">
              <article className="newsShow">
                <h1>
                  Դաժան ծեծկռտուք Քասախում: Վիրավորը տեղափոխվել է «Աստղիկ
                  ԲԿ»:&nbsp;
                </h1>
                <a
                  className="articleCat"
                  href="https://www.lurer.com/?cat=27&amp;l=am"
                >
                  Արտակարգ Պատահար
                </a>
                <time>13 Նոյեմբեր, 2022 11:37</time>
                <div className="articleMedia">
                  <img
                    src={photoOwner}
                    title="Դաժան ծեծկռտուք Քասախում. Կա վիրավոր։&nbsp;"
                  />
                </div>
                <p>
                  Երեկ՝ Նոյեմբերի 12-ին, արտակարգ դեպք է տեղի ունեցել Կոտայքի
                  մարզ գյուղ Քասախում: Ժամը 18:00-ի սահմաններում, իր իսկ
                  առանձնատան մոտակայքում, դաժան ծեծի է ենթարկվել Sololearn
                  ընկերության ծրագրավորող Էդմոն Մարգարյանը:
                </p>
                <p>
                  Ըստ ականատեսների նա դաժան ծեծի է ենթարկվել մի քանի անձանց
                  կողմից: Հարևաններից մեկը`Վարդան Հակոբյանը, ով չցանկացավ
                  տեսանկարահանվել, պատմեց.
                  <p>&nbsp;</p>
                  <i>
                    «Տանը նստած էի, փողոցից լսեցի գոռոցներ, սովորաբար այստեղ
                    նման բաներ չեն լինում, սա շատ հանգիստ փողոց է: Գոռգոռոցներ
                    լսելուն պես, վազեցի դուրս և տեսա որ մի քանի հոգի քաշքշում են
                    մեր հարևան էդմոնին, ում դեմքը արյունոտ էր: Իմ և հարևանների
                    օգնության հասնելուն պես, նրանք նստեցին մեքենան ու հայհոյելով
                    հեռացան:»
                  </i>{" "}
                  <p>&nbsp;</p>
                  Մեր հարցին,թե ովքեր և ինչու են ծեծի ենթարկել Էդմոն
                  Մարգարյանին, նա պատասխանեց.
                  <i>
                    {" "}
                    «Վերջին օրերին էդմոնը և իր ընտանիքը տանը չէին, մեքենան էլ
                    չկար: Մի անգամ հեռախոսով խոսել եմ էդմոնի հետ, կարճ ասել է,
                    որ ինչ որ լուրջ խնդիրներ ունեն: Իսկ թե ովքեր էին այդ անձինք,
                    դա չգիտեմ:»
                  </i>
                </p>
                &nbsp;
                <p>
                  Էդմոն Մարգարյանի հարազատները և ընկերները հաստատել են, որ
                  Էդմոնը ունի խնդիրներ ծրագրավորման ոլորտում օտարերկրյա
                  պատվիրատուների հետ և մանրամասների մասին տեղյակ է նաև
                  ոստիկանությունը:
                </p>
                &nbsp;
                <p>
                  Ոստիկանությունից տեղեկացանք, որ դեպքի հանգամանքները պարզվում
                  են:
                </p>
                &nbsp;
                <p>
                  Կապվել ենք «ԱՍՏՂԻԿ ԲԿ»-ի աշխատակազմի հետ և տեղեկացել, որ Էդմոն
                  Մարգարյանը ունի վնասվածքներ, ձեռքի բարդ ոլորաձև կոտրվածք, քթի
                  կոտրվածք և պետք է կատարվի բարդ վիրահատություն: Վիճակը գնահվում
                  է կայուն:
                </p>
                <div id="inpagevideo" className="inpage_adf"></div>
                <div id="caramel_interscroller"></div>
                <p>&nbsp;</p>
                <div class="relatedArticles">
                  <b>Այս թեմայով</b>
                  <a
                    href="https://lurer.com/?p=562431&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/18-03-24/d9e93498c86dc50ee575f9cf843bac33.jpg&amp;w=120&amp;h=80" />
                    Քոլեջի բակում երեխաներն իրար են սպանում. քրեական տարր
                    պարունակող հաղորդումները պետք է սահմանափակվեն
                  </a>
                  <a
                    href="https://lurer.com/?p=562411&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/18-03-24/804fc5b67052371cab4227120c649a4c.jpg&amp;w=120&amp;h=80" />
                    Պարույր Սևակ փողոցում զենքով խուլիգանության դեպքը
                    բացահայտվել է․ 2 անձի մեղադրանք է ներկայացվել
                  </a>
                  <a
                    href="https://lurer.com/?p=562370&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/17-03-24/d9c625176cd8d6f0faad427b36f5de0b.jpg&amp;w=120&amp;h=80" />
                    Ավտովթար՝ Արարատի մարզում. 32-ամյա վարորդը «Nissan»-ով
                    բախվել է ծառին. կա վիրավոր
                  </a>
                  <a
                    href="https://lurer.com/?p=562297&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/17-03-24/3451df3eaedb36581cfad4057c0d9154.jpg&amp;w=120&amp;h=80" />
                    Լոռու մարզում կրծքավանդակի ձախ կեսի հրազենային վնասվածքով
                    հիվանդանոց է տեղափոխվել 38-ամյա տղամարդ
                  </a>
                  <a
                    href="https://lurer.com/?p=562281&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/17-03-24/015a6a2fd3a8541f5134cb56b39a0102.jpg&amp;w=120&amp;h=80" />
                    27-ամյա վարորդը «Փարվանա» ռեստորանի մոտ «Opel»-ով գլխիվայր
                    հայտնվել է Հրազդան գետում
                  </a>
                  <a
                    href="https://lurer.com/?p=562140&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/16-03-24/174b8ac137798f0631f7a9e5c3598d68.jpg&amp;w=120&amp;h=80" />
                    Mitsubishi-ն բախվել է բետոնե սյանը. վարորդը տեղում մահացել
                    է. ավտոմեքենայում եղել են կինն ու դուստրը
                  </a>
                  <a
                    href="https://lurer.com/?p=562021&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/15-03-24/171d6b168d007856bb4f37feb0ddcfe5.jpg&amp;w=120&amp;h=80" />
                    Ֆուչիկի փողոցում տեղի ունեցած միջադեպը ինձ հետ որևէ
                    առնչություն չունի․ Սարգիս Խանդանյան
                  </a>
                  <a
                    href="https://lurer.com/?p=562023&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/15-03-24/94ff1dfd0802acd6ec3114908bd5c7c6.jpg&amp;w=120&amp;h=80" />
                    Ֆուչիկի փողոցում զենքի գործադրմամբ կատարված խուլիգանության և
                    սպանության փորձերի դեպքի հանգամանքները պարզվում են
                  </a>
                  <a
                    href="https://lurer.com/?p=561917&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/15-03-24/d63e7cd8a63d39e119191b45ce6bd88d.jpg&amp;w=120&amp;h=80" />
                    52-ամյա տղամարդը հետախուզվում էր խարդախության մեղադրանքով
                  </a>
                  <a
                    href="https://lurer.com/?p=561887&amp;l=am"
                    class="clearfix"
                  >
                    <img src="https://lurer.com/timthumb.php?src=disc/15-03-24/176b814ecd727ba340f0e6fa9633d212.jpg&amp;w=120&amp;h=80" />
                    «Հրապարակ». Աջապահյանի եղբորորդուն բերման են ենթարկել եւ
                    «քաղմասում» դաժան ծեծել
                  </a>
                </div>
              </article>
            </div>
            <div className="mainCenterWrapperRight">
              <div className="subYT">
                <span>
                  Հետևե՛ք <img src="https://lurer.com/img/logo.png" />
                  -ին Youtube-ում`
                  <script
                    src="https://apis.google.com/js/platform.js"
                    gapi_processed="true"
                  ></script>
                  <div
                    id="___ytsubscribe_0"
                    style={{
                      textIndent: "0px",
                      margin: "0px",
                      padding: "0px",
                      background: "transparent",
                      borderStyle: "none",
                      float: "none",
                      lineHeight: "normal",
                      fontSize: "1px",
                      verticalAlign: "baseline",
                      display: "inline-block",
                      width: "110px",
                      height: "24px",
                    }}
                  >
                    <iframe
                      ng-non-bindable=""
                      frameborder="0"
                      hspace="0"
                      marginheight="0"
                      marginwidth="0"
                      scrolling="no"
                      style={{
                        position: "static",
                        top: "0px",
                        width: "110px",
                        margin: "0px",
                        borderStyle: "none",
                        left: "0px",
                        visibility: "visible",
                        height: "24px",
                      }}
                      tabindex="0"
                      vspace="0"
                      width="100%"
                      id="I0_1706138867612"
                      name="I0_1706138867612"
                      src="https://www.youtube.com/subscribe_embed?usegapi=1&amp;channelid=UCieDeR0tACeZyDIJWP7jdIQ&amp;layout=default&amp;count=default&amp;origin=https%3A%2F%2Flurer.com&amp;gsrc=3p&amp;ic=1&amp;jsh=m%3B%2F_%2Fscs%2Fabc-static%2F_%2Fjs%2Fk%3Dgapi.lb.en.y0xCMa4KeeI.O%2Fd%3D1%2Frs%3DAHpOoo8-3MGCaatZB3kdS5TpZdd-gOSBHg%2Fm%3D__features__#_methods=onPlusOne%2C_ready%2C_close%2C_open%2C_resizeMe%2C_renderstart%2Concircled%2Cdrefresh%2Cerefresh%2Conload&amp;id=I0_1706138867612&amp;_gfid=I0_1706138867612&amp;parent=https%3A%2F%2Flurer.com&amp;pfname=&amp;rpctoken=31897447"
                      data-gapiattached="true"
                    ></iframe>
                  </div>
                </span>
              </div>

              <div className="titler">
                <a href="https://www.lurer.com/?news_line&amp;l=am">Լրահոս</a>
              </div>
              <div class="timeline">
                <a
                  href="https://www.lurer.com/?p=563469&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/a1e3282de68a94d4de8117b38f5f7d8a.jpg&amp;w=90&amp;h=90" />
                  <span>ՆԱՏՕ-ն 2-3 ամսից զորքեր կուղարկի Ուկրաինա. Օրբան</span>
                  <time class="timeago" datetime="2024-03-22 14:11:48">
                    մոտ 3 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563464&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/d384ecbae23cacd039a9c7133a4e1278.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Ակադեմիական քաղաքը մետրո ու տրամվայ է ունենալու, իսկ
                    երթուղին կսկսի «Սասունցի Դավիթ» կայարանից
                  </span>
                  <time class="timeago" datetime="2024-03-22 14:05:06">
                    մոտ 3 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563453&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/454cfed88be33c7b149a605de8e12df4.jpg&amp;w=90&amp;h=90" />
                  <span>Ինչպիսի եղանակ է սպասվում առաջիկա օրերին</span>
                  <time class="timeago" datetime="2024-03-22 13:57:40">
                    մոտ 3 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563468&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/41ca1c8764e64a31c9343ce44a02e184.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>ՄԻՊ գրասենյակից այցելել են Նարեկ Սամսոնյանին </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:53:18">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563441&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/a366f2a5b3bc9582aa64e96806720a54.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Ինչպես փոխել կենսաթոշակը կամ նպաստը վճարող բանկը առցանց
                      եղանակով
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:50:46">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563455&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/5cf1ae02c107a13ab406fa321a55eea6.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Հայտնի են Հայաստանի հավաքականի ֆուտբոլիստների խաղային
                    համարները Կոսովոյի դեմ խաղում
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:42:57">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563462&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/224b6d99392c64f6bb9b9ee424d3b1f6.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Նարեկ Սամսոնյանին ամենակեղտոտ ձևով ձերբակալող ուժայինները
                      վաղը նույն ձևով Փաշինյանին ու իր հրամանները կատարողներին
                      են գետնին տալով ձերբակալելու
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:35:19">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563458&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/bf286cd912fd791d2232de32aa8f8a9b.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Մոնղոլիայում անոմալ սառնամանիքների պատճառով՝ ավելի քան 4,7
                    միլիոն կենդանի է սատկել{" "}
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:31:19">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563442&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/e981081a64698263ac5307995d83e38a.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Փաշինյանը ոչ միայն միակողմանի զիջման է գնում, այլ դեռ մի
                      բան էլ ասում է, որ սահմանները «ֆիքսված չեն». ադրբեջանագետ
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:27:05">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563459&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/00694deb0c68a749be8419981f6334a6.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Սամսոնյանի վրա հոդված են կարում, դա քաղաքական բռնաճնշում է
                    ու հարված ընդդիմադիր խոսքին․ Մելիքյան
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:21:20">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563457&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/388b396529bbca7e4c316ce7ae812b37.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Արարատ Միրզոյանն Ուրուգվայի իր գործընկերոջն է ներկայացրել
                      «Խաղաղության խաչմերուկ» ծրագիրը
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:19:41">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563440&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/c3088456dfcc33cf0745f60b059e547f.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Աղմուկ կա Ակադեմիական քաղաքի ծրագրի շուրջ, ոչինչ տեղի չի
                    ունենալու փակ դռների հետևում․ ԿԳՄՍ նախարար
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:12:19">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563450&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/7beccbcda38b08223b02c71c2cbab66d.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Գիշերը Ռուսաստանն Ուկրաինայի ուղղությամբ արձակել է ավելի
                      քան 60 «Շահեդ» և 90 հրթիռ. Զելենսկի
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:08:13">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563435&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/e4155a9fd1ae18c8deecd9a843328b4c.jpg&amp;w=90&amp;h=90" />
                  <span>Վազաշենի հարակից անտառից ապօրինի ծառեր են հատվել</span>
                  <time class="timeago" datetime="2024-03-22 13:04:36">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563446&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/99b3bd88b4a4a65c420b099056f80e7b.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      ՈՒՂԻՂ․ Առողջապահության հարցերի մշտական հանձնաժողովի
                      արտահերթ նիստ
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 13:03:18">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563411&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/0af1e7d06297d14eb655f62f29ae40ee.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Արտաշատի ոստիկանները մեքենայից կատարված գողության դեպք են
                    բացահայտել
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:57:48">
                    մոտ 2 ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563407&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/079a4a55141bc6d91b5836c50d3ca32e.jpg&amp;w=90&amp;h=90" />
                  <span>Գազամատակարարման պլանային դադարեցում</span>
                  <time class="timeago" datetime="2024-03-22 12:50:26">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563439&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/3e28cd16f94c471e5b29549a648894f1.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Քննչական կոմիտեն մանրամասներ է հայտնել Նարեկ Սամսոնյանի
                      ձերբակալությունից
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:48:26">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563438&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/c297c27c10ab101e4e96b857b304b3a4.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      2023 թվականին դատարան ուղարկված վարույթների գերակշռող մասը
                      վերաբերել են գողությանը և թմրամիջոցներին
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:44:08">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563404&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/fc00ec3949698a5bb731e0cca01834f4.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Օպերատիվ իրավիճակը հանրապետությունում մարտի 21-ից մարտի 22-ը
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:42:30">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563437&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/0d58f68b49ed6a53786f8cedf7dd85e1.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Զրույցներ պետության մասին-2, իրավունքի և պաշտպանության
                      աղբյուրը. Փաշինյան
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:41:21">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563436&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/c7b5ca798a3a6694af9f1baca5f4bbdd.jpg&amp;w=90&amp;h=90" />
                  <span>Ձերբակալել են Նարեկ Սամսոնյանին</span>
                  <time class="timeago" datetime="2024-03-22 12:40:39">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563395&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/17bda0d57fd3e9d1a64d52b7d67a7fc6.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Բռնցքամարտիկ Բարեղամ Հարությունյանն առնվազն բրոնզե մեդալ է
                    ապահովել միջազգային մրցաշարում
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:35:41">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563397&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/f4fc020e40dbc9625f26f7d01dcce696.jpg&amp;w=90&amp;h=90" />
                  <span>Կանաչապատման նախագիծ՝ Երևանյան լճի համար </span>
                  <time class="timeago" datetime="2024-03-22 12:27:36">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563398&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/19a2e062df70f438a5db645030cf4322.jpg&amp;w=90&amp;h=90" />
                  <span>
                    ՀՀ-ում Կանադայի դեսպանին ներկայացրի իմ հայրենի Գեղարքունիքի
                    մարզի անվտանգային խնդիրները. Թագուհի Թովմասյան
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:20:08">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563394&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/7e9f636f9e74babb3e5eb79005f7afad.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Արաբկիր վարչական շրջանի բակերում իրականացվում են մաքրման
                    աշխատանքներ՝ ապօրինի տարածքներից (տեսանյութ)
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:12:01">
                    45 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563424&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/d5864996648503c4eff029bd7aaa1c3a.jpg&amp;w=90&amp;h=90" />
                  <span>
                    «Ոսկե վիզա» ստանալու համար նվազագույն գումարը կավելանա
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:08:46">
                    41 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563396&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/040bee2232791d096bbb44fbe9a40fdb.jpg&amp;w=90&amp;h=90" />
                  <span>Տարադրամի փոխարժեքները մարտի 22-ին</span>
                  <time class="timeago" datetime="2024-03-22 12:07:30">
                    40 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563430&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/aa5c5c928c1ebe5c87ef43c15e17cca2.jpg&amp;w=90&amp;h=90" />
                  <span>
                    «Համերգներ, դասընթացներ ու վերապատրաստումներ ենք
                    կազմակերպել»․ Սինանյանը ամփոփում է աշխատանքները
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:01:41">
                    34 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=562939&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/21-03-24/8e4fa6921e7a10bd80e02cce69dcabc5.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Ալիևի երազանքների իրականացմանը մեր կողմից վերջ է տրվելու․
                      Արմեն Մկրտչյան
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 12:00:19">
                    33 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563421&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/404470015911c59d64169578cb507b7d.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Մոսկվայում լույս կտեսնի Առնո Բաբաջանյանի
                      ստեղծագործությունների բազմահատոր ժողովածուն
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:54:00">
                    27 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563393&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/e09e452f038e4c1105e87d899c16951d.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Գևորգ Պապոյանը մարտի ընդունել է Համաշխարհային բանկի
                    հայաստանյան գրասենյակի ղեկավարի գլխավորած պատվիրակությանը
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:53:12">
                    26 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563408&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/1f099da5331e3f9e5cd1dc032882d852.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Այսօր Ժնևում տեղի կունենա Ալեն Սիմոնյանի և Սահիբա Գաֆարովայի
                    հանդիպումը
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:46:46">
                    19 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563391&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/d966d6682366f0ea640ee701c7786564.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Ավտովթար՝ Երևանում․ «BMW»-ն մխրճվել է ավտոքարշակի հետնամասի
                    մեջ, ավտոքարշակն էլ բախվել է «Jeep»-ին
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:45:51">
                    18 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563409&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/0842f78c8ba260688ba07cdcc4f9a0a8.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>Նարեկ Մկրտչյանը հանդիպել է «Լիբրա խմբի» նախագահին</b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:37:50">
                    10 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563390&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/c80edaae4636a663b2abd27279664719.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Այսօր նշվում է ջրի համաշխարհային, իսկ Հայաստանում՝ Ջրաբանի
                    օրը
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:37:30">
                    10 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563406&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/e8b5df725bfa34ede3fad5fb46077d3e.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Թալինում հրդեհ է բռնկվել ավտոկայանատեղիում․ այրվել են
                    տրակտոր ու բեռնատար
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:30:01">
                    3 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563389&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/b78152952ed8cce9b33777a265691a31.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Իրավիճակը ՀՀ-ում զարգանում է ծայրահեղ վտանգավոր սցենարով.
                    Հովհաննիսյան
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:30:00">
                    3 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563405&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/3892f70adf6204bbbc40351d381b3c91.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Ռուսաստանն ամենամեծ հարձակումն է իրականացրել Ուկրաինայի
                      էներգետիկ համակարգի վրա
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:26:48">
                    վայրկյաններ առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563388&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/4aac3e2e226ad311045b5d515f95c60f.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Դանակահարություն ՝ Հրազդանի կիրճում գտնվող ռեստորանում. կա
                      վիրավոր
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:22:45">
                    5 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563399&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/9097e10d9a776ef3db9551d49bdbab91.jpg&amp;w=90&amp;h=90" />
                  <span>
                    «Սյունիք» օդանավակայանը կարող է ծառայել միջազգային չվերթներ
                    իրականացնելուն
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:15:00">
                    12 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563387&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/7fbe29a0bba1ad6d95ff20b8d7b3c125.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Հիմա արժանապատիվ Հայաստանն ունի երկու թշնամի. Վարդան
                    Ոսկանյան
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:14:40">
                    13 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563402&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/07d864584399525e1008b17c9ba2aab3.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      Ինչ պետք է իմանալ մի քանի աղբյուրից փոխանցված սոցվճարների
                      առավելագույն չափը գերազանցող գումարների վերադարձի մասին
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:09:27">
                    18 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563382&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/75c7a9bf9a035126e6e616170221e178.jpg&amp;w=90&amp;h=90" />
                  <span>Երևանում և 6 մարզերում լույս չի լինի</span>
                  <time class="timeago" datetime="2024-03-22 11:07:00">
                    20 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563400&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/8970b0aa2ee6d1c16d1eeb37a89ffe7c.jpg&amp;w=90&amp;h=90" />
                  <span>
                    «Պաշտպան հայրենյաց» ծրագրի շահառուների ծառայությունը
                    (տեսանյութ)
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:06:00">
                    21 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563401&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/3d0c1b95cfe0da78a86d72c4038c7b92.jpg&amp;w=90&amp;h=90" />
                  <span>
                    <b>
                      ՈւՂԻՂ. ԱԺ Գիտության, կրթության, մշակույթի և սպորտի հարցերի
                      հանձնաժողովի նիստ
                    </b>
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:03:43">
                    24 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563384&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/68e61c665c18b0f1aca8b5638171e4be.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Հանրապետության ամբողջ տարածքում սպասվում են տեղումներ{" "}
                  </span>
                  <time class="timeago" datetime="2024-03-22 11:00:33">
                    27 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563386&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/a53a015e9d5b7f161024b95030817984.jpg&amp;w=90&amp;h=90" />
                  <span>Մարտի 22-ի աստղագուշակ</span>
                  <time class="timeago" datetime="2024-03-22 10:52:29">
                    35 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563381&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/b97c7dfe09471d78d25108468d3e9e51.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Թմրամոլության դեմ Գեղարքունիքի պարեկների պայքարի դրվագներ
                  </span>
                  <time class="timeago" datetime="2024-03-22 10:45:48">
                    42 րոպե առաջ
                  </time>
                </a>
                <a
                  href="https://www.lurer.com/?p=563378&amp;l=am"
                  class="clearfix"
                >
                  <img src="https://www.lurer.com/timthumb.php?src=disc/22-03-24/20964251dca7c0427a57fd1d95d26046.jpg&amp;w=90&amp;h=90" />
                  <span>
                    Ջերմուկ տանող ճանապարհին իրականացվել է քարերի հեռացում
                  </span>
                  <time class="timeago" datetime="2024-03-22 10:37:34">
                    մոտ մեկ ժամ առաջ
                  </time>
                </a>
              </div>

              <div className="allTimeline">
                <a href="https://www.lurer.com/?news_line&amp;l=am">
                  Ամբողջ լրահոսը »
                </a>
              </div>
              {/* <div
                style={{
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src="https://lurer.com//banners/sas.gif?v=2"
                    width="100%"
                  />
                  <a
                    href="https://supermarket.am/"
                    target="_blank"
                    rel="nofollow"
                    style={{
                      display: "block",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  ></a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="mostPopularWrapper">
          <div className="mostPopular">
            <div className="mpTitle clearfix">
              <span>
                <img src="https://lurer.com/img/most.png" /> Ամենադիտված
              </span>
              <select className="popSelect">
                <option selected="" data-mv="lw">
                  Վերջին 7 օրվա
                </option>
                <option data-mv="lm">Վերջին ամսվա</option>
                <option data-mv="ltm">Վերջին 2 ամսվա</option>
              </select>
            </div>
            <div className="mpAJAX">
              <div className="lw">
                <div className="mostPopularTimeline owl-carousel clearfix owl-loaded owl-drag">
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform: "translate3d(-2640px, 0px, 0px)",
                        transition: "all 0.25s ease 0s",
                        width: "4800px",
                      }}
                    >
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=551063&amp;l=am">
                            <b>6</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/22-01-24/6d002e80d992f17320903ad52a499db7.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-22 11:30:00"
                              >
                                3 օր առաջ
                              </time>
                              «Զվարթնոց» օդանավակայանի տարածքում հայտնաբերել են
                              հետախուզման մեջ գտնվող 33-ամյա Մասիս Ա.-ին
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=551239&amp;l=am">
                            <b>7</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/22-01-24/64846f5b139c2d3867611f0fb55e9920.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-22 21:43:12"
                              >
                                2 օր առաջ
                              </time>
                              Ուղևորը թռիչքից րոպեներ առաջ նկատել է ինքնաթիռի
                              թևի վրայից բացակայող պտուտակները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550453&amp;l=am">
                            <b>8</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/18-01-24/c9f78af871e4547c52954c86d9b15ebe.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 18:23:45"
                              >
                                6 օր առաջ
                              </time>
                              Արամ Ասատրյանի հղի կինը կաթիլայիններ է ստանում.
                              ի՞նչ է պատահել
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550509&amp;l=am">
                            <b>9</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/18-01-24/eb07b6ed101c0471f4671917883e7e8c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 22:18:52"
                              >
                                6 օր առաջ
                              </time>
                              Կինը բնակարան է վարձել և այնտեղ գաղտնի սենյակ
                              հայտնաբերել
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=551248&amp;l=am">
                            <b>10</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/22-01-24/15b2e94166b6db96cd68a9f58b2f226c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-22 22:48:28"
                              >
                                2 օր առաջ
                              </time>
                              Հունվարի 27-ին նոր ցիկլոն կգա Հայաստան, որը ևս
                              տեղումնաբեր կլինի. Սուրենյան․ ՀԺ
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item" style={{ width: "240px" }}>
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550943&amp;l=am">
                            <b>1</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/20-01-24/3dfa25ca7ee947b207007d915a1f5e9c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-20 22:51:03"
                              >
                                4 օր առաջ
                              </time>
                              Համացանցում են հայտնվել Ադրբեջանում կալանքի տակ
                              գտնվող Արայիկ Հարությունյանի լուսանկարները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item" style={{ width: "240px" }}>
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550996&amp;l=am">
                            <b>2</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/21-01-24/efc79de82a1c4176ecde6c6b407e182d.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-21 16:30:09"
                              >
                                3 օր առաջ
                              </time>
                              Թուրքական հեռուստաալիքով քննարկում են Թուրքիայի
                              քարտեզը. Հայաստանը քարտեզի վրա նույնիսկ
                              խոշորացույցով չի երևում
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item" style={{ width: "240px" }}>
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550526&amp;l=am">
                            <b>3</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/18-01-24/5118cc8af44d531e34183792e8fcb053.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 23:59:04"
                              >
                                6 օր առաջ
                              </time>
                              Լավրովը նշել է, թե որ երկրներն են Ռուսաստանի
                              դաշնակիցները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item" style={{ width: "240px" }}>
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550731&amp;l=am">
                            <b>4</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/19-01-24/aa80d47dcd1b3d2b546694d6c2bfa5ca.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-19 18:42:24"
                              >
                                5 օր առաջ
                              </time>
                              «Լավ կլիներ, որ մի 20 տարի առաջ հանեին»․ Նազենի
                              Հովհաննիսյանին ազատել են աշխատանքից
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item" style={{ width: "240px" }}>
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550968&amp;l=am">
                            <b>5</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/21-01-24/f2d300ca152a52b01d7abb0831772e26.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-21 11:50:55"
                              >
                                4 օր առաջ
                              </time>
                              Նա իր համն ու հոտը փոխանցեց երևանցիներին. հանգչես
                              խաղաղությամբ, սիրելի՛ Ժիրայր. Ավինյան
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item" style={{ width: "240px" }}>
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=551063&amp;l=am">
                            <b>6</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/22-01-24/6d002e80d992f17320903ad52a499db7.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-22 11:30:00"
                              >
                                3 օր առաջ
                              </time>
                              «Զվարթնոց» օդանավակայանի տարածքում հայտնաբերել են
                              հետախուզման մեջ գտնվող 33-ամյա Մասիս Ա.-ին
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=551239&amp;l=am">
                            <b>7</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/22-01-24/64846f5b139c2d3867611f0fb55e9920.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-22 21:43:12"
                              >
                                2 օր առաջ
                              </time>
                              Ուղևորը թռիչքից րոպեներ առաջ նկատել է ինքնաթիռի
                              թևի վրայից բացակայող պտուտակները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550453&amp;l=am">
                            <b>8</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/18-01-24/c9f78af871e4547c52954c86d9b15ebe.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 18:23:45"
                              >
                                6 օր առաջ
                              </time>
                              Արամ Ասատրյանի հղի կինը կաթիլայիններ է ստանում.
                              ի՞նչ է պատահել
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550509&amp;l=am">
                            <b>9</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/18-01-24/eb07b6ed101c0471f4671917883e7e8c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 22:18:52"
                              >
                                6 օր առաջ
                              </time>
                              Կինը բնակարան է վարձել և այնտեղ գաղտնի սենյակ
                              հայտնաբերել
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item active"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=551248&amp;l=am">
                            <b>10</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/22-01-24/15b2e94166b6db96cd68a9f58b2f226c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-22 22:48:28"
                              >
                                2 օր առաջ
                              </time>
                              Հունվարի 27-ին նոր ցիկլոն կգա Հայաստան, որը ևս
                              տեղումնաբեր կլինի. Սուրենյան․ ՀԺ
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned active"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550943&amp;l=am">
                            <b>1</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/20-01-24/3dfa25ca7ee947b207007d915a1f5e9c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-20 22:51:03"
                              >
                                4 օր առաջ
                              </time>
                              Համացանցում են հայտնվել Ադրբեջանում կալանքի տակ
                              գտնվող Արայիկ Հարությունյանի լուսանկարները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550996&amp;l=am">
                            <b>2</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/21-01-24/efc79de82a1c4176ecde6c6b407e182d.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-21 16:30:09"
                              >
                                3 օր առաջ
                              </time>
                              Թուրքական հեռուստաալիքով քննարկում են Թուրքիայի
                              քարտեզը. Հայաստանը քարտեզի վրա նույնիսկ
                              խոշորացույցով չի երևում
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550526&amp;l=am">
                            <b>3</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/18-01-24/5118cc8af44d531e34183792e8fcb053.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 23:59:04"
                              >
                                6 օր առաջ
                              </time>
                              Լավրովը նշել է, թե որ երկրներն են Ռուսաստանի
                              դաշնակիցները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550731&amp;l=am">
                            <b>4</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/19-01-24/aa80d47dcd1b3d2b546694d6c2bfa5ca.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-19 18:42:24"
                              >
                                5 օր առաջ
                              </time>
                              «Լավ կլիներ, որ մի 20 տարի առաջ հանեին»․ Նազենի
                              Հովհաննիսյանին ազատել են աշխատանքից
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="owl-item cloned"
                        style={{ width: "240px" }}
                      >
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550968&amp;l=am">
                            <b>5</b>
                            <img src="https://lurer.com/timthumb.php?src=disc/21-01-24/f2d300ca152a52b01d7abb0831772e26.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-21 11:50:55"
                              >
                                4 օր առաջ
                              </time>
                              Նա իր համն ու հոտը փոխանցեց երևանցիներին. հանգչես
                              խաղաղությամբ, սիրելի՛ Ժիրայր. Ավինյան
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-nav disabled">
                    <button
                      type="button"
                      role="presentation"
                      className="owl-prev"
                    >
                      <span aria-label="Previous">‹</span>
                    </button>
                    <button
                      type="button"
                      role="presentation"
                      className="owl-next"
                    >
                      <span aria-label="Next">›</span>
                    </button>
                  </div>
                  <div className="owl-dots">
                    <button role="button" className="owl-dot">
                      <span></span>
                    </button>
                    <button role="button" className="owl-dot active">
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="lm">
                <div className="mostPopularTimeline owl-carousel clearfix owl-loaded owl-drag">
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform: "translate3d(0px, 0px, 0px)",
                        transition: "all 0s ease 0s",
                      }}
                    >
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550943&amp;l=am">
                            <b>1</b>
                            <img src="/timthumb.php?src=disc/20-01-24/3dfa25ca7ee947b207007d915a1f5e9c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-20 22:51:03"
                              >
                                4 օր առաջ
                              </time>
                              Համացանցում են հայտնվել Ադրբեջանում կալանքի տակ
                              գտնվող Արայիկ Հարությունյանի լուսանկարները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=547710&amp;l=am">
                            <b>2</b>
                            <img src="/timthumb.php?src=disc/04-01-24/340b9d1a81a099fa4ddc5a05bc5cc2c7.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-4 19:38:56"
                              >
                                20 օր առաջ
                              </time>
                              Արտակարգ դեպք՝ Երևանում
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=548010&amp;l=am">
                            <b>3</b>
                            <img src="/timthumb.php?src=disc/06-01-24/9cd04a61fdd603f6e9d430aa5e40a7d6.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-6 18:45:25"
                              >
                                18 օր առաջ
                              </time>
                              ՀՀ ՊՆ–ն հայտարարություն է տարածել
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=547367&amp;l=am">
                            <b>4</b>
                            <img src="/timthumb.php?src=disc/02-01-24/b8a1ea3212c30d5d1b8c20e442c81c9d.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-2 16:40:36"
                              >
                                22 օր առաջ
                              </time>
                              ՌԴ-ում աշխատող միգրանտները հունվարի 7-ից պետք է
                              տեղեկացնեն երկրում աշխատանք սկսելու մասին
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=549946&amp;l=am">
                            <b>5</b>
                            <img src="/timthumb.php?src=disc/16-01-24/a989973cdcf2e2bb36be198419a4f107.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-16 22:12:54"
                              >
                                8 օր առաջ
                              </time>
                              Հունվարի 28-ից ԼՂ մեր հայրենակիցները ճամփորդելիս
                              պետք է ունենան նաև ժամանակավոր պաշտպանության
                              վկայական․ ՆԳՆ
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550996&amp;l=am">
                            <b>6</b>
                            <img src="/timthumb.php?src=disc/21-01-24/efc79de82a1c4176ecde6c6b407e182d.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-21 16:30:09"
                              >
                                3 օր առաջ
                              </time>
                              Թուրքական հեռուստաալիքով քննարկում են Թուրքիայի
                              քարտեզը. Հայաստանը քարտեզի վրա նույնիսկ
                              խոշորացույցով չի երևում
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=547702&amp;l=am">
                            <b>7</b>
                            <img src="/timthumb.php?src=disc/04-01-24/5f92de3c5205dbd2cde8a4634d69c3a1.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-4 18:23:40"
                              >
                                20 օր առաջ
                              </time>
                              Պուտինը հրամանագիր է ստորագրել օտարերկրացիներին
                              քաղաքացիություն տրամադրելու մասին
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550526&amp;l=am">
                            <b>8</b>
                            <img src="/timthumb.php?src=disc/18-01-24/5118cc8af44d531e34183792e8fcb053.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 23:59:04"
                              >
                                6 օր առաջ
                              </time>
                              Լավրովը նշել է, թե որ երկրներն են Ռուսաստանի
                              դաշնակիցները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=548127&amp;l=am">
                            <b>9</b>
                            <img src="/timthumb.php?src=disc/07-01-24/c44144c35cd8ee5849bec0695e70fad7.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-7 22:30:27"
                              >
                                17 օր առաջ
                              </time>
                              Թուրքիայի տրանսպորտի նախարարը հայտարարել է, թե
                              «Զանգեզուրի միջանցքը» կստեղծվի 2028 թվականին
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550731&amp;l=am">
                            <b>10</b>
                            <img src="/timthumb.php?src=disc/19-01-24/aa80d47dcd1b3d2b546694d6c2bfa5ca.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-19 18:42:24"
                              >
                                5 օր առաջ
                              </time>
                              «Լավ կլիներ, որ մի 20 տարի առաջ հանեին»․ Նազենի
                              Հովհաննիսյանին ազատել են աշխատանքից
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-nav disabled">
                    <button
                      type="button"
                      role="presentation"
                      className="owl-prev"
                    >
                      <span aria-label="Previous">‹</span>
                    </button>
                    <button
                      type="button"
                      role="presentation"
                      className="owl-next"
                    >
                      <span aria-label="Next">›</span>
                    </button>
                  </div>
                  <div className="owl-dots">
                    <button role="button" className="owl-dot">
                      <span></span>
                    </button>
                    <button role="button" className="owl-dot active">
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ltm">
                <div className="mostPopularTimeline owl-carousel clearfix owl-loaded owl-drag">
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform: "translate3d(0px, 0px, 0px)",
                        transition: "all 0s ease 0s",
                      }}
                    >
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550943&amp;l=am">
                            <b>1</b>
                            <img src="/timthumb.php?src=disc/20-01-24/3dfa25ca7ee947b207007d915a1f5e9c.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-20 22:51:03"
                              >
                                4 օր առաջ
                              </time>
                              Համացանցում են հայտնվել Ադրբեջանում կալանքի տակ
                              գտնվող Արայիկ Հարությունյանի լուսանկարները
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=547710&amp;l=am">
                            <b>2</b>
                            <img src="/timthumb.php?src=disc/04-01-24/340b9d1a81a099fa4ddc5a05bc5cc2c7.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-4 19:38:56"
                              >
                                20 օր առաջ
                              </time>
                              Արտակարգ դեպք՝ Երևանում
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=548010&amp;l=am">
                            <b>3</b>
                            <img src="/timthumb.php?src=disc/06-01-24/9cd04a61fdd603f6e9d430aa5e40a7d6.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-6 18:45:25"
                              >
                                18 օր առաջ
                              </time>
                              ՀՀ ՊՆ–ն հայտարարություն է տարածել
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=547367&amp;l=am">
                            <b>4</b>
                            <img src="/timthumb.php?src=disc/02-01-24/b8a1ea3212c30d5d1b8c20e442c81c9d.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-2 16:40:36"
                              >
                                22 օր առաջ
                              </time>
                              ՌԴ-ում աշխատող միգրանտները հունվարի 7-ից պետք է
                              տեղեկացնեն երկրում աշխատանք սկսելու մասին
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=549946&amp;l=am">
                            <b>5</b>
                            <img src="/timthumb.php?src=disc/16-01-24/a989973cdcf2e2bb36be198419a4f107.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-16 22:12:54"
                              >
                                8 օր առաջ
                              </time>
                              Հունվարի 28-ից ԼՂ մեր հայրենակիցները ճամփորդելիս
                              պետք է ունենան նաև ժամանակավոր պաշտպանության
                              վկայական․ ՆԳՆ
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=543380&amp;l=am">
                            <b>6</b>
                            <img src="/timthumb.php?src=disc/07-12-23/727d9bf848a28965450859b4a80542d6.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2023-12-7 16:49:20"
                              >
                                2 ամիս առաջ
                              </time>
                              Իսրայելը ճանաչել է Հայաստանը՝ որպես տարածաշրջանում
                              ամենաանվտանգ երկիր
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550996&amp;l=am">
                            <b>7</b>
                            <img src="/timthumb.php?src=disc/21-01-24/efc79de82a1c4176ecde6c6b407e182d.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-21 16:30:09"
                              >
                                3 օր առաջ
                              </time>
                              Թուրքական հեռուստաալիքով քննարկում են Թուրքիայի
                              քարտեզը. Հայաստանը քարտեզի վրա նույնիսկ
                              խոշորացույցով չի երևում
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=543913&amp;l=am">
                            <b>8</b>
                            <img src="/timthumb.php?src=disc/11-12-23/d7ffe49629be28ede55f3dc8ff721235.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2023-12-11 22:05:47"
                              >
                                մոտ մեկ ամիս առաջ
                              </time>
                              ՀՀ ՊՆ–ն հայտարարություն է տարածել
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=547702&amp;l=am">
                            <b>9</b>
                            <img src="/timthumb.php?src=disc/04-01-24/5f92de3c5205dbd2cde8a4634d69c3a1.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-4 18:23:40"
                              >
                                20 օր առաջ
                              </time>
                              Պուտինը հրամանագիր է ստորագրել օտարերկրացիներին
                              քաղաքացիություն տրամադրելու մասին
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="owl-item">
                        <div className="mostPopularTimelineUnit clearfix">
                          <a href="https://www.lurer.com/?p=550526&amp;l=am">
                            <b>10</b>
                            <img src="/timthumb.php?src=disc/18-01-24/5118cc8af44d531e34183792e8fcb053.jpg&amp;w=210&amp;h=145?v=1" />
                            <span>
                              <img src="https://lurer.com/img/pop_arr.png" />
                              <time
                                className="timeago"
                                dateTime="2024-01-18 23:59:04"
                              >
                                6 օր առաջ
                              </time>
                              Լավրովը նշել է, թե որ երկրներն են Ռուսաստանի
                              դաշնակիցները
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-nav disabled">
                    <button
                      type="button"
                      role="presentation"
                      className="owl-prev"
                    >
                      <span aria-label="Previous">‹</span>
                    </button>
                    <button
                      type="button"
                      role="presentation"
                      className="owl-next"
                    >
                      <span aria-label="Next">›</span>
                    </button>
                  </div>
                  <div className="owl-dots">
                    <button role="button" className="owl-dot">
                      <span></span>
                    </button>
                    <button role="button" className="owl-dot active">
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="clearfix">
        <div className="footerLeft">
          <small>
            © 2011-2022 Lurer.com Մեջբերումներ անելիս ակտիվ հղումը Lurer.com-ին
            պարտադիր է: Կայքի հոդվածների մասնակի կամ ամբողջական
            հեռուստառադիոընթերցումն առանց Lurer.com-ին հղման արգելվում է:Կայքում
            արտահայտված կարծիքները պարտադիր չէ, որ համընկնեն կայքի խմբագրության
            տեսակետի հետ:Գովազդների բովանդակության համար կայքը
            պատասխանատվություն չի կրում:
          </small>
        </div>
        <div className="footerRight">
          <div className="footerMenu">
            <a href="https://www.lurer.com/?st=1&amp;l=am">Հետադարձ կապ</a>
            <a href="https://www.lurer.com/?st=2&amp;l=am">Մեր մասին</a>
            <a href="https://www.lurer.com/?st=3&amp;l=am">Գովազդատուներին</a>
          </div>
        </div>
        <div className="signature">
          Կայքի պատրաստում և սպասարկում՝
          <a href="http://sargssyan.com" target="_blank">
            sargssyan™
          </a>
        </div>
      </footer>
    </>
  );
};

export default Main;
